import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from '../modules'

const middleWares = [thunk]

const createStoreWithMiddlewares = applyMiddleware(...middleWares)(createStore)

const configureStore = (initialState) => createStoreWithMiddlewares(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default configureStore()
