import types from '../../../constants/action-types'

const defaultState = {
  data: {}
}

const map = {
  [types.FETCH_USER_DATA]: (state, {payload})=>({
    ...state,
    data: payload
  })
}

export default (state, action) => (map[action.type] && map[action.type](state, action)) || state || defaultState