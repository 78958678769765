import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Cookies from "universal-cookie"
import axios from 'axios';
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'


axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
const cookie = new Cookies();
axios.interceptors.request.use(
  config => {
    // if (!config.headers.Authorization) {
    //   const token = JSON.parse(localStorage.getItem("keyCloak")).token;
  
    let token =  cookie.get("access_token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(response => {
    // Edit response config
    return response;
}, error => {
  const notyf = new Notyf()
    console.log(error);
    if(parseInt(error.response.status) === 422){
      notyf.error(error.response.data.message)
    }
    return Promise.reject(error);
});





ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
